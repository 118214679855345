<!--景点-->
<template>
    <div class="beautiful-vlliage w-1400">
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/xiangninghui'}">乡宁荟</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/xiangninghui'}">乡宁游</el-breadcrumb-item>
                <el-breadcrumb-item >景点</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row :gutter="25">
            <el-col :span="18">
                <div class="search-box">
                     <div class="clearfix">
                        <label class="fl">位置</label>
                        <i class="fl" style="margin-right:20px">:</i>
                        <div class="fl" :class="{isactive:!params.filter.regionCode}" @click="params.filter.regionCode = 0;getPageList()">不限</div>
                        <ul class="clearfix fl search-list">
                            <li  v-for="(item) in region" class="fl classify-item" :key="item.code" :class="{isactive:params.filter.regionCode == item.code}" @click="params.filter.regionCode = item.code;getPageList()">{{item.name}}</li>
                        </ul>
                    </div>

                    <div class="clearfix">
                        <label class="fl">适合人群</label>
                        <i class="fl" style="margin-right:20px">:</i>
                        <div class="fl" :class="{isactive:!params.filter.suitablePeople}" @click="params.filter.suitablePeople = '';getPageList()">不限</div>
                        <ul class="clearfix fl search-list">
                            <li  v-for="(item) in suitablePeople" class="fl classify-item" :key="item" :class="{isactive:params.filter.suitablePeople == item}" @click="params.filter.suitablePeople = item;getPageList()">{{item}}</li>
                        </ul>
                    </div>

                    <div class="clearfix">
                        <label class="fl">适合季节</label>
                        <i class="fl" style="margin-right:20px">:</i>
                        <div class="fl" :class="{isactive:!params.filter.bestTimes}" @click="params.filter.bestTimes = '';getPageList()">不限</div>
                        <ul class="clearfix fl search-list">
                            <li  v-for="(item) in bestTimes" class="fl classify-item" :key="item" :class="{isactive:params.filter.bestTimes == item}" @click="params.filter.bestTimes = item;getPageList()">{{item}}</li>
                        </ul>
                    </div>
             
                    <div class="villiage-list">
                        <div class="v-list-sort">
                            <span class="isactive">推荐排序</span>
                            <span>最新<i class="el-icon-sort"></i></span>
                        </div>
                    </div>
                </div>
                
                <div class="v-list-item clearfix" v-for="item in pageData.list" :key="item.id+'list'">
                    <div class="v-item-pic fl">
                        <router-link :to="`/scenerySpotdetail?id=${item.id}`">
                            <el-image style="width:100%;height:100%" :src="item.photoUrls&&item.photoUrls.split(',')[0]"></el-image>
                        </router-link>
                    </div>
                    <div class="v-item-info fl">
                        <div class="v-item-name">{{item.name}}</div>
                        <div class="v-item-address">{{item.address}}</div>
                        <div class="v-item-phone">联系电话：{{item.telephone}}</div>
                        <div class="v-item-detail">
                            {{item.introduction}}
                        </div>
                    </div>
                </div>
                <el-pagination class="pagination" background layout="prev, pager, next,jumper" :total="pageData.total" @current-change="handleCurrentChange"> </el-pagination>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import {GetScenerySpotPage} from '@/api/home.js'
export default {
    data(){
        return{
             region:[
                // { name: "全部", code: 0 },
                { name: "大通县", code: 630121 },
                { name: "湟源县", code: 630123 },
                { name: "湟中区", code: 630122 },
                { name: "城东区", code: 630102 },
                { name: "城中区", code: 630103 },
                { name: "城西区", code: 630104 },
                { name: "城北区", code: 630105 },
            ],
            suitablePeople:['家庭游','亲子游','情侣游','团体游','其他'],
            bestTimes:['春季','夏季','秋季','冬季','四季皆宜'],

            pageData:{},
            params:{
                currentPage:1,
                filter: {name: "", bestTimes:"",suitablePeople:"",regionCode:0},
                pageSize: 10
            },
            
        }
    },
    methods:{
        getPageList(){
            GetScenerySpotPage(this.params).then(res=>{
                this.pageData=res.data.data;
            })
        },
        //分页
        handleCurrentChange(page) {
            this.params.currentPage = page;
            this.getPageList();
        },
    },
    mounted(){
        this.getPageList();
    }
}
</script>
<style scoped>
.notice >>> .el-carousel__button{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.notice-item >>> .el-carousel__indicators--horizontal{
    bottom: 18px;
    left: auto;
    right: 10px;
    transform: translateX(0);
}
.pagination{
    text-align: center;
}
.notice-text{
    height: 28px;
    line-height: 28px;
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    color:#fff
}
.notice-info{
    position: relative;
    height: 150px;
}
notice-pic{
    width: 100%;
    height: 150px;
    overflow: hidden;
}
.notice-name{
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 700;
}
.notice-list{
    border:1px solid #ccc;
    padding:10px;
}
.notice-title a{
    color: #fff;
}
.notice-title{
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-weight: 600;
    background: #de0049;
    color: #fff;
    padding: 0 10px;
}
.notice{
    /* width: 370px; */
}
.v-item-shoppingtime{
    position: absolute;
    top: 14px;
    right: 10px;
    background: #f9773b;
    padding: 12px;
    font-size: 14px;
    color:#fff;
}
.v-item-detail{
    font-size: 14px;
    line-height: 22px;
    text-indent: 24px;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:4;
    -webkit-box-orient:vertical;
}
.v-item-address,.v-item-phone{
    font-size: 14px;
    height: 24px;
    line-height: 24px;
}
.v-item-name{
    font-size: 18px;
    font-weight: 600;
    height: 36px;
    line-height: 36px;
}
.v-item-info{
    width: calc(100% - 310px);
    margin-left: 20px;
    position: relative;
    font-family: '新宋体';
}
.v-item-pic{
    width: 290px;
    height: 180px;
    overflow: hidden;
}
.v-list-item{
    height: 200px;
    overflow: hidden;
    padding: 10px;
    background: #eee;
    margin-bottom:20px ;
}
.v-list-sort span{
    font-size: 16px;
    font-weight: 800;
    height: 36px;
    line-height: 36px;
    margin-right: 30px;
}
.search-list{
    max-width: 620px;
}
.search-box span.isactive,.v-list-sort span.isactive,.isactive{
    color:#4283d2;
}
.search-box{
    margin-top:20px;
    padding: 5px;
}
.search-box div{
    font-size: 18px;
    font-weight: 700;
    /* height: 40px; */
    line-height: 40px;
}
.search-box label{
    width: 100px;
    text-align: justify;
    text-align-last: justify;
}
.classify-item{
    padding: 0px 20px;
    font-size: 18px;
    /* font-weight: 600; */
    cursor: pointer;

}
.breadcrumb >>> .el-breadcrumb .is-link,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__separator,.breadcrumb >>> .el-breadcrumb .el-breadcrumb__inner{
    font-size: 18px;
    font-family: '微软雅黑';
    color: #0e6ac1;
}
.breadcrumb{
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #0e6ac1;
    margin-bottom: 50px;
}
</style>